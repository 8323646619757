import { Component, Inject } from '@angular/core';
import { AppStorage } from '@shared/for-storage/universal.inject';
import { MetaService } from '@ngx-meta/core';
import { Metrika } from 'ng-yandex-metrika';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { CartService } from './models/cart.service';
import { ComponentsService } from './models/components.service';
import { ProductService } from './models/product.service';
import { CategoryService } from './models/category.service';
import { ToppingService } from './models/topping.service';
import { PageService } from './models/page.service';
import { GeolocationService } from './models/geolocation.service';
import { CityService } from './models/city.service';
import { WrapperComponent } from '@shared/layouts/wrapper/wrapper.component';
import { PointService } from './models/point.service';
const uuidv4 = require('uuid/v4')


@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  providers: [
    ToppingService,
    CategoryService,
    ProductService,
    CartService,
    ComponentsService,
    PageService,
    GeolocationService,
    CityService,
    PointService
  ]
})
export class AppComponent {
  public error: string

  constructor(
    private readonly meta: MetaService,
    @Inject(AppStorage) private appStorage: Storage,
    private metrika: Metrika,
    private router: Router,
    private location: Location,

  ) {
    this.meta.setTag('og:title', 'Суши Гурмэ')

    if (!this.appStorage.getItem('sessionId')) {
      this.appStorage.setItem('sessionId', uuidv4());
    }

    // yandex metrica
    let prevPath = this.location.path();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      const newPath = this.location.path();
      this.metrika.hit(newPath, {
        referer: prevPath,
      });
      prevPath = newPath;
    });
  }
}
