import {Component, Inject, OnChanges, OnInit} from '@angular/core';
import {CartService} from '../../../models/cart.service';
import { Page } from '../../../models/page.model'
import { City } from '../../../models/city.model'
import {Location} from '@angular/common';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {PageService} from '../../../models/page.service';
import {CityService} from '../../../models/city.service';
import {GeolocationService} from '../../../models/geolocation.service';
import {AppStorage} from '@shared/for-storage/universal.inject';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { environment } from '../../../../environments/environment'

import * as $ from 'jquery'
import {Category} from '../../../models/category.model';
import {CategoryService} from '../../../models/category.service';
declare var UIkit: any

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html'
})
export class WrapperComponent implements OnInit {
  public categories: Category[]
  public pages: Page[]
  public cities: City[]
  public cityForm: FormGroup
  public isRootRoute: boolean
  public isMap: boolean
  public isCityBoxIn: boolean
  public isCityBoxOut: boolean
  public isTimeBoxIn: boolean
  public isTimeBoxOut: boolean
  public isBannerBoxIn: boolean
  public isBannerBoxOut: boolean
  public totalCart: number = 0
  public city: string
  public error: string
  public categoriesImages: any
  public apiUrl: string

  constructor(private location: Location,
              private cartService: CartService,
              private pageService: PageService,
              private geolocationService: GeolocationService,
              private cityService: CityService,
              private categoryService: CategoryService,
              @Inject(AppStorage) private appStorage: Storage) {
    this.calculateCart()
    this.isRootRoute = true
    this.isMap = false
    this.isCityBoxIn = false
    this.isCityBoxOut = false
    this.isTimeBoxIn = false
    this.isTimeBoxOut = false
    this.isBannerBoxIn = false
    this.isBannerBoxOut = false
    this.city = this.appStorage.getItem('city')
  }

  ngOnInit() {
    this.getCategories()

    $('#open-info-modal').click(() => {
      $('body').toggleClass('view-menu-modal')
    })
    $('.container-bottom-menu a:not(#open-info-modal)').click(() => {
      $('body').removeClass('view-menu-modal')
    })
    $('#info-modal *').click(() => {
      $('body').removeClass('view-menu-modal')
    })

    $('#open-menu-modal').click(() => {
      $('body').toggleClass('view-menu-modal')
    })
    $('.container-bottom-menu a:not(#open-menu-modal)').click(() => {
      $('body').removeClass('view-menu-modal')
    })
    $('#menu-modal *').click(() => {
      $('body').removeClass('view-menu-modal')
    })

    this.getPages()

    this.getCurrentDate()

    if (this.city === null || this.city === '') {
      this.appStorage.setItem('city', environment.defaultCity)
      this.getGeolocation()
    }
  }

  getCurrentDate() {
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const mins = currentDate.getMinutes();
    if (hours >= 23 || hours <= 10) {
        if (hours === 23 && mins >=45) {
            this.isTimeBoxIn = true;
        } else if (hours === 10 && mins <=15) {
            this.isTimeBoxIn = true;
        } else if (hours !== 23 && hours !== 10) {
            this.isTimeBoxIn = true;   
        }
    }
    // if(!this.isTimeBoxIn) {
    //   this.isBannerBoxIn = true;
    // }
  }

  closeTimeBox() {
    this.isTimeBoxIn = false;
    this.isTimeBoxOut = true;
    this.isBannerBoxIn = true;
  }

  closeBannerBox() {
    this.isBannerBoxIn = false;
  }

  getCategories() {
    this.categoryService.getCategories()
        .subscribe(data => {
          this.categories = data['response']
          this.categoriesImages = []

          data['response'].map(category => {
            let imagesString = ''
            let count = 0
            category.images.map(image => {
              if (image.ext === 'jpeg') {
                count++
                if (count !== 4) {
                  imagesString += this.apiUrl + image.src + ' ' + image.width + 'w, '
                } else {
                  imagesString += this.apiUrl + image.src + ' ' + image.width + 'w'
                }
              }
            })
            this.categoriesImages.push(imagesString)
          })
        }, error => {
          this.error = error
        })
  }

  changeRoute() {
    $('body').removeClass('lazy-load-body')
    setTimeout(() => {
      $('body').addClass('lazy-load-body')
    }, 0)
  }

  closeMenuModal() { }

  notify(image: string, message: string) {
    UIkit.notification({
      message: `<div class="notif-content uk-flex uk-flex-middle">` +
      `<div class="notif-img uk-cover-container"><img src="${ image }"></div>` +
      `<div class="notif-text uk-width-expand">${ message }</div></div>`,
      status: 'primary',
      pos: 'top-center',
      timeout: 3000
    });
  }

  getPages() {
    this.pageService.getPages()
      .subscribe(data => {
        this.pages = data['response']
      }, error => {
        this.error = error;
      })
  }

  calculateCart() {
    this.cartService.calculateCart()
      .subscribe(data => {
        this.totalCart = data['response']
      }, error => {
        this.error = error;
      })
  }

  clearCart() {
    this.cartService.clearCart()
      .subscribe(data => {
        this.totalCart = 0
      }, error => {
        this.error = error;
      })
  }

  goBack() {
    this.location.back()
  }

  getState(outlet) { }

  // Get user geolocation
  getGeolocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.getCity(position.coords.latitude, position.coords.longitude, 'ru')
      }, error => {
        this.getCities()
      })
    } else {
      this.getCities()
      console.log('Geolocation is not supported by this browser.')
    }
  }

  // Set city by data form
  setCity() {
    this.appStorage.setItem('city', this.cityForm.get('city').value)
    this.isCityBoxOut = true
  }

  // Get city by google by user coordinates
  getCity(latitude: number, longitude: number, language: string) {
    this.geolocationService.getCity(latitude, longitude, language)
      .subscribe(data => {
        // Get city by name which google api return
        this.getCityByName(data['results'][0]['address_components'][4]['long_name'])
      }, error => {
        this.error = error;
      })
  }

  // Get cities list which delivery available
  getCities() {
    this.cityService.getCities()
      .subscribe(data => {
        this.cities = data['response']
        this.generateCityForm()
        this.isCityBoxIn = true
      }, error => {
        this.error = error;
      })
  }

  // Get city by name
  getCityByName(name: string) {
    this.cityService.getCityByName(name)
      .subscribe(data => {
        if (data['response'] === null) {
          this.getCities()
        } else {
          this.appStorage.setItem('city', data['response'])
        }
      }, error => {
        this.error = error;
      })
  }

  // Generate city form
  generateCityForm() {
    this.cityForm = new FormGroup({
      city: new FormControl(this.cities[0]['name'], [
        Validators.required
      ])
    })
  }
}
