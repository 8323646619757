import { Routes, RouterModule } from '@angular/router';
import { MetaGuard } from '@ngx-meta/core';

import { WrapperComponent } from '@shared/layouts/wrapper/wrapper.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '', component: WrapperComponent, canActivateChild: [MetaGuard], children: [
      { path: 'home', loadChildren: './home/home.module#HomeModule', data: { state: 'home' } },
      { path: 'category/:category_id', loadChildren: './category/category.module#CategoryModule', data: { state: 'category' } },
      { path: 'product/:product_id', loadChildren: './product/product.module#ProductModule', data: { state: 'product' } },
      { path: 'components/:product_id', loadChildren: './components/components.module#ComponentsModule', data: { state: 'components' } },
      { path: 'cart', loadChildren: './cart/cart.module#CartModule', data: { state: 'cart' } },
      { path: 'delivery', loadChildren: './delivery/delivery.module#DeliveryModule', data: { state: 'delivery' } },
      { path: 'order', loadChildren: './order/order.module#OrderModule', data: { state: 'order' } },
      { path: 'contacts', loadChildren: './contacts/contacts.module#ContactsModule', data: { state: 'contacts' } },
      { path: 'page/:page_key', loadChildren: './page/page.module#PageModule', data: { state: 'page' } },
      { path: '**', loadChildren: './not-found/not-found.module#NotFoundModule' },
    ]
  }
];
// must use {initialNavigation: 'enabled'}) - for one load page, without reload
export const AppRoutes = RouterModule.forRoot(routes, { initialNavigation: 'enabled' });
