import {Inject, Injectable} from '@angular/core';
import { environment } from '../../environments/environment'

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'

import { Observable ,  of } from 'rxjs'

import { catchError } from 'rxjs/operators'

import { Product } from './product.model'
import { Topping } from './topping.model'
import { HttpErrorHandler, HandleError } from './http-error-handler.service'

import {AppStorage} from '@shared/for-storage/universal.inject';

const API_URL = environment.apiUrl

@Injectable()
export class CartService {
  cartUrl = '/cart'
  private handleError: HandleError
  private httpOptions: object

  constructor(private http: HttpClient,
              httpErrorHandler: HttpErrorHandler,
              @Inject(AppStorage) private appStorage: Storage) {
      this.httpOptions = {
          headers: new HttpHeaders({
              'Content-Type':  'application/json',
              'Session-Id':  this.appStorage.getItem('sessionId'),
              'x-refresh': 'true'
          })
      }
      this.handleError = httpErrorHandler.createHandleError('CartService');
  }

  addProductToCart(product: Product): Observable<Product[]> {
      return this.http.post<Product[]>(API_URL + this.cartUrl + '/product', product, this.httpOptions)
          .pipe(
              catchError(this.handleError('addProductToCart', []))
          )
  }

  addProductWithComponentsToCart(product: Product, components: any): Observable<Product[]> {
    return this.http.post<Product[]>(API_URL + this.cartUrl + '/product-with-components', { product: product, components: components }, this.httpOptions)
      .pipe(
        catchError(this.handleError('addProductWithComponentsToCart', []))
      )
  }

  deleteProductFromCart(productId: string): Observable<Product[]> {
    return this.http.delete<Product[]>(API_URL + this.cartUrl + '/product/' + productId, this.httpOptions)
      .pipe(
        catchError(this.handleError('deleteProductFromCart', []))
      )
  }

  deleteProductWithComponentsFromCart(productId: string, components: any): Observable<Product[]> {
    return this.http.delete<Product[]>(API_URL + this.cartUrl + '/product-with-components/' + productId + '/' + components, this.httpOptions)
      .pipe(
        catchError(this.handleError('deleteProductWithComponentsFromCart', []))
      )
  }

  deleteProductFullFromCart(productId: string): Observable<Product[]> {
    return this.http.delete<Product[]>(API_URL + this.cartUrl + '/product/full/' + productId, this.httpOptions)
      .pipe(
        catchError(this.handleError('deleteProductFullFromCart', []))
      )
  }

  deleteProductWithComponentsFullFromCart(productId: string, components: any): Observable<Product[]> {
    return this.http.delete<Product[]>(API_URL + this.cartUrl + '/product-with-components/full/' + productId + '/' + components, this.httpOptions)
      .pipe(
        catchError(this.handleError('deleteProductWithComponentsFullFromCart', []))
      )
  }

  addToppingToProduct(topping: Topping, productId: string): Observable<Topping[]> {
    return this.http.post<Topping[]>(API_URL + this.cartUrl + '/topping/' + productId, topping, this.httpOptions)
      .pipe(
        catchError(this.handleError('addToppingToProduct', []))
      )
  }

  deleteToppingFromProduct(productId: string, toppingId: string): Observable<Product[]> {
    return this.http.delete<Product[]>(API_URL + this.cartUrl + '/topping/' + productId + '/' + toppingId, this.httpOptions)
      .pipe(
        catchError(this.handleError('deleteToppingFromProduct', []))
      )
  }

  deleteToppingFullFromProduct(productId: string, toppingId: string): Observable<Product[]> {
    return this.http.delete<Product[]>(API_URL + this.cartUrl + '/topping/full/' + productId + '/' + toppingId, this.httpOptions)
      .pipe(
        catchError(this.handleError('deleteToppingFromProduct', []))
      )
  }

  getCart(): Observable<Product[]> {
    return this.http.get<Product[]>(API_URL + this.cartUrl + '/' + this.appStorage.getItem('sessionId'), this.httpOptions)
      .pipe(
        catchError(this.handleError('getCart', []))
      )
  }

  sendOrder(cart: any, orderData: any, paymentData: any, pickupData: any): Observable<Product[]> {
    return this.http.post<Product[]>(API_URL + this.cartUrl + '/sendOrder/' + this.appStorage.getItem('sessionId'), { cart, orderData, paymentData, pickupData }, this.httpOptions)
        .pipe(
            catchError(this.handleError('getCart', []))
        )
  }

  calculateCart(): Observable<any> {
    return this.http.get<any>(API_URL + this.cartUrl + '/calculate/' + this.appStorage.getItem('sessionId'), this.httpOptions)
      .pipe(
        catchError(this.handleError('calculateCart', []))
      )
  }

  clearCart(): Observable<any> {
    return this.http.delete<any>(API_URL + this.cartUrl, this.httpOptions)
      .pipe(
        catchError(this.handleError('calculateCart', []))
      )
  }
}
